<template>
  <div
    class="mobile_list"
    :style="{ height: height || reallyHeight }"
    :id="`${mobileListId}mobile_list`"
  >
    <div
      v-for="(item, index) in paiData"
      class="list_item"
      :key="item"
      v-show="paiData.length"
    >
      <slot :name="'default'" v-bind="{ row: item, $index: index }" />
    </div>
    <div class="no_data" v-show="!loading && !paiData.length">
      <img src="../../assets/img/pic_250x150_nothing@2x.png" />
      <p>暂无数据</p>
    </div>

    <InfiniteLoading
      :target="`#${mobileListId}mobile_list`"
      @infinite="load"
      :identifier="mobileListId"
      :distance="0"
    >
      <template #spinner>
        <div class="mobile_list_bottom" v-show="loading || paiData.length">
          <template v-if="loading || internalLoading">
            <img src="../../assets/img/icon_18x18_loading_024ac2@2x.png" />
            <p>加载中</p>
          </template>
          <template v-if="endData">
            <p>没有更多了~</p>
          </template>
        </div>
      </template>
    </InfiniteLoading>
  </div>
</template>

<script setup>
import {
  ref,
  watch,
  reactive,
  useAttrs,
  onMounted,
  nextTick,
  computed
} from "vue";
import { ElMessage } from "element-plus";
import InfiniteLoading from "v3-infinite-loading";
import { generateRandomString } from "@/utils/index";

const paiData = ref([]);
const emit = defineEmits([
  "update:loading",
  "update:manualOpenCallback",
  "selectionChange",
  "dataSuccessCallback",
  "manualCallback"
]);

const tableRef = ref();

const attrs = useAttrs();

const props = defineProps({
  defaultSort: {
    type: Object,
    default: () => {
      return {};
    }
  },
  data: {
    type: Array
  },
  loading: {
    type: Boolean,
    default: false
  },
  isDalog: {
    type: Boolean,
    default: false
  },
  height: {
    type: String
  },
  manualOpenCallback: {
    type: Boolean,
    default: false
  },
  pageNum: {
    type: Boolean,
    default: true
  },
  selection: {
    type: Boolean,
    default: false
  },
  pagination: {
    type: Boolean,
    default: true
  },
  smallPagination: {
    type: Boolean,
    default: false
  },
  paginationCenter: {
    type: Boolean,
    default: false
  },
  columns: {
    type: Array
  },
  reqFun: {
    type: Function,
    default: () => {}
  },
  reqParams: {
    type: Object,
    default: () => ({})
  },
  frontCallback: {
    type: Function
  },
  pointer: {
    type: Boolean
  },
  isObj: {
    type: Boolean
  },
  paginationAlign: {
    type: String,
    default: "flex-end"
  },
  pageList: {
    type: Array,
    default: () => [20, 50, 100]
  },
  rowClassName: {
    type: String,
    default: ""
  },
  noTop: {
    type: Boolean,
    default: true
  },
  occupyTop: { type: Number, default: 0 },
  pageSize: {}
});

const pageParams = ref({
  pageIndex: 1,
  pageSize: props.pageSize || 20
});
//  总条数
const total = ref(0);
const multipleSelection = ref([]);
const mobileListId = ref(generateRandomString(12));
const endData = ref(false);
//  表格的高度
const reallyHeight = ref("500px");
// 内部的loading
const internalLoading = ref(false);

const selectComputed = computed(
  () => item => multipleSelection.value.includes(item)
);

const onCheckboxChange = (val, item) => {
  if (val) {
    multipleSelection.value.push(item);
  } else {
    const index = multipleSelection.value.findIndex(fItem => fItem === item);
    multipleSelection.value.splice(index, 1);
  }
};

const tableHieght = () => {
  //  搜索区域的高度
  let topHeight =
    (document.querySelector(".mobile_list") &&
      document.querySelector(".mobile_list")?.getBoundingClientRect()?.top) ||
    0;

  return `calc(100vh - ${topHeight}px)`;
};

const load = () => {
  console.log(pageParams.value.pageIndex, "pageParams.value");
  if (pageParams.value.pageIndex === 1) {
    return;
  }
  if (endData.value) {
    return;
  }
  if (props.loading || internalLoading.value) {
    return;
  }
  console.log("load");
  getTableData();
  internalLoading.value = true;
};

const getTableData = () => {
  const {
    reqFun,
    reqParams,
    // eslint-disable-next-line
    pageNum,
    pagination,
    manualOpenCallback,
    frontCallback,
    isObj
  } = props;
  // eslint-disable-next-line
  const empty = pagination ? pageParams.value : {};

  if (typeof reqFun === "function") {
    reqFun({
      // ...empty,
      ...reqParams,
      ...(pagination
        ? {
            pageSize: empty.pageSize || reqParams.pageSize || 50,
            pageNum: empty.pageIndex || 1
          }
        : {})
    })
      .then(res => {
        emit("update:loading", false);
        internalLoading.value = false;
        if (frontCallback) {
          return frontCallback(res).then(val => {
            if (pageParams.value.pageIndex === 1) {
              paiData.value = val.data;
              emit("selectionChange", []);
            } else {
              paiData.value = [...paiData.value, ...val.data];
            }
            pageParams.value.pageIndex = pageParams.value.pageIndex + 1;
            console.log(pageParams.value.pageIndex, "pageParams.value1111");
            total.value = Number(val.total);
            if (paiData.value.length == val.total) {
              endData.value = true;
            }
          });
        }

        if (res.code === 200) {
          if (isObj) {
            return (paiData.value = res.data ? [res.data] : []);
          }
          if (res.data === null) {
            paiData.value = [];
          } else {
            if (pageParams.value.pageIndex === 1) {
              paiData.value = Array.isArray(res.data)
                ? res.data
                : res.data.list;
              emit("selectionChange", []);
            } else {
              paiData.value = Array.isArray(res.data)
                ? [...paiData.value, ...res.data]
                : [...paiData.value, ...res.data.list];
            }
            pageParams.value.pageIndex = pageParams.value.pageIndex + 1;
          }
          total.value = Number(res.data.total) || res.data.totalRecords || 0;
          console.log(paiData.value.length, res.data.total, "res.data.total");

          if (paiData.value.length == res.data.total) {
            endData.value = true;
          }

          if (manualOpenCallback) {
            emit(
              "manualCallback",
              Array.isArray(res.data) ? res.data : res.data.list
            );
            emit("update:manualOpenCallback", false);
          } else {
            emit("dataSuccessCallback", res);
          }
        }
      })
      .catch(() => {
        emit("update:loading", false);
      });
  }
};

const operationTips = curRow => {
  return new Promise(reslove => {
    if (curRow) return reslove();
    if (!Array.from(multipleSelection.value).length) {
      return ElMessage({
        showClose: true,
        message: "请选择一条数据操作！",
        type: "warning"
      });
    }
    if (Array.from(multipleSelection.value).length !== 1) {
      return ElMessage({
        showClose: true,
        message: "只能操作一条数据！",
        type: "warning"
      });
    }
    reslove();
  });
};

// 上移
const moveUp = curRow => {
  operationTips(curRow).then(() => {
    const [currentRow] = curRow ? [curRow] : multipleSelection.value;
    const currentIndex = paiData.value.findIndex(item => item === currentRow);

    if (currentIndex === 0) return;
    paiData.value.splice(currentIndex, 1);
    paiData.value.splice(currentIndex - 1, 0, currentRow);
  });
};

// 下移
const moveDown = curRow => {
  operationTips(curRow).then(() => {
    const [currentRow] = curRow ? [curRow] : multipleSelection.value;
    const currentIndex = paiData.value.findIndex(item => item === currentRow);
    if (currentIndex > paiData.value.length) return;
    paiData.value.splice(currentIndex, 1);
    paiData.value.splice(currentIndex + 1, 0, currentRow);
  });
};
// 置顶
const topping = curRow => {
  operationTips(curRow).then(() => {
    const [currentRow] = curRow ? [curRow] : multipleSelection.value;
    const currentIndex = paiData.value.findIndex(item => item === currentRow);
    paiData.value.splice(currentIndex, 1);
    paiData.value.splice(0, 0, currentRow);
  });
};
// 置底
const setLow = curRow => {
  operationTips(curRow).then(() => {
    const [currentRow] = curRow ? [curRow] : multipleSelection.value;
    const currentIndex = paiData.value.findIndex(item => item === currentRow);
    paiData.value.splice(currentIndex, 1);
    paiData.value.push(currentRow);
  });
};
// 删除
const deleteData = curRow => {
  operationTips(curRow).then(() => {
    const [currentRow] = curRow ? [curRow] : multipleSelection.value;
    const currentIndex = paiData.value.findIndex(item => item === currentRow);
    paiData.value.splice(currentIndex, 1);
  });
};

// 删除全部所选
const deleteDataAll = () => {
  multipleSelection.value.forEach(Fitem => {
    const currentIndex = paiData.value.findIndex(item => item === Fitem);
    paiData.value.splice(currentIndex, 1);
  });
};

// 插入对象
const pushData = (curRow, addRow) => {
  const [currentRow] = curRow ? [curRow] : multipleSelection.value;
  const currentIndex = paiData.value.findIndex(item => item === currentRow);
  paiData.value.splice(currentIndex, 0, addRow);
};

const handleData = (type, curRow, addRow) => {
  switch (type) {
    case 0:
      moveUp(curRow);
      break;
    case 1:
      moveDown(curRow);
      break;
    case 2:
      topping(curRow);
      break;
    case 3:
      setLow(curRow);
      break;
    case 4:
      deleteData(curRow);
      break;
    case 5:
      deleteDataAll();
      break;
    case 6:
      pushData(curRow, addRow);
      break;
  }
};
const resetForm = () => {
  pageParams.value.pageIndex = 1;
  pageParams.value.pageSize = props.reqParams?.pageSize || 50;
};

watch(
  () => props.loading,
  newValue => {
    if (newValue) {
      pageParams.value.pageIndex = 1;
    }

    setTimeout(() => {
      newValue && getTableData();
    }, 10);
  },
  { immediate: true }
);

watch(
  () => props.data,
  newValue => {
    if (newValue) {
      paiData.value = newValue;
    }
  },
  { deep: true, immediate: true }
);

//  初始化表格高度
onMounted(() => {
  reallyHeight.value = tableHieght();
  resetForm();
});

watch(
  () => paiData.value,
  val => {
    console.log(paiData.value, "paiData.value");
  },
  { deep: true }
);

watch(
  () => pageParams.value,
  () => {
    console.log(pageParams.value, "pageParamswatch");
  },
  { deep: true }
);

defineExpose({
  handleData,
  resetForm,
  tableData: () => paiData.value,
  setTableData: () => paiData
});
</script>
<style lang="scss" scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.mobile_list {
  background-color: #f8f9f9;
  overflow: auto;

  .list_item {
    margin-top: 4px;
    background-color: #fff;
    padding: 12px 8px;
  }

  .no_data {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 250px;
      height: 150px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      line-height: 17px;
      text-align: center;
    }
  }

  .mobile_list_bottom {
    width: 100%;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 18px;
      height: 18px;
      animation: spin 2s linear infinite;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 16px;
      margin-top: 4px;
    }
  }
}
</style>

<template>
  <div>
    <div v-if="slots.default" @click="onPreview(row)">
      <slot />
    </div>
    <el-button v-else type="primary" @click="onPreview(row)">预览</el-button>
    <QRcodeCom v-model:visible="QRcodeComVisible" />
  </div>
</template>
<script setup lang="ts">
import { ref, useSlots } from "vue";
import { loadEnv } from "@build/index";
import { nodecontPreviewSign } from "@/api/lanmu";
import { perviewH5 } from "@/utils/index";
import QRcodeCom from "@/components/QRcodeCom/index.vue";

const props = defineProps({
  row: {
    type: Object,
    default: () => ({})
  },
  contIdKey: {
    type: String,
    default: "contId"
  }
});

const slots = useSlots();

const { VITE_APP_PAPER_WAP } = loadEnv();

const QRcodeComVisible = ref("");

const onPreview = data => {
  // let {contId,}
  if (VITE_APP_PAPER_WAP) {
    nodecontPreviewSign({
      contentEditId: data[props.contIdKey]
    }).then(res => {
      if (res.code === 200) {
        QRcodeComVisible.value = perviewH5(
          data[props.contIdKey],
          data.contType,
          data.link,
          res.data,
          null
        );
      }
    });
  }
};
</script>

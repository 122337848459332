<template>
  <div class="mobile_item">
    <div class="item_top">
      <template v-if="!special">
        <img
          v-if="row.contType == 0"
          src="../../assets/img/icon_24x24_tuwen@2x.png"
        />
        <img
          v-if="row.contType == 1"
          src="../../assets/img/icon_24x24_shipin.png"
        />
        <img
          v-if="row.contType == 2"
          src="../../assets/img/icon_24x24_shipinlive@2x.png"
        />
        <img
          v-if="row.contType == 3"
          src="../../assets/img/icon_24x24_wailian@2x.png"
        />
        <img
          v-if="row.contType == 4"
          src="../../assets/img/icon_24x24_tuwenlive@2x.png"
        />
        <img
          v-if="row.contType == 5"
          src="../../assets/img/icon_24x24_tuji@2x.png"
        />
      </template>

      <div class="title_div" @click="onTitleClick">
        {{ row.shortTitle || row.title }}
      </div>
      <div class="node_div" v-if="nodeShow">{{ row.nodeName }}</div>
      <div class="checkbox_div" @click="onCheckboxClick" v-if="useCheckbox">
        <img
          src="../../assets/img/icon_18x18_sel_off@2x.png"
          v-if="!multipleSelection?.some(item => item === row)"
        />
        <img src="../../assets/img/icon_18x18_sel_on_409eff@2x.png" v-else />
      </div>
    </div>
    <div class="item_bottom">
      <div class="info_left">
        <template v-for="(value, name) in slots" :key="name">
          <div
            class="slot_div"
            v-if="name !== 'special'"
            :style="
              special ? 'background:#fff;padding:0px;margin-right:10px' : ''
            "
          >
            <slot :name="name" />
          </div>
        </template>
      </div>
      <div class="info_right" v-if="!special">
        <el-tooltip effect="dark" placement="bottom">
          <template #content> 复制自ID：{{ row.copyContId }} </template>

          <img
            v-if="row.copyContId"
            src="../../assets/img/icon_18x18_copyinfo_666666@2x.png"
          />
        </el-tooltip>

        <div v-if="showCreateUserName">
          {{ row[nameKey] }}
        </div>
        <div v-if="row.wordCount || row.wordCount === 0">
          {{ row.wordCount }}字
        </div>
        <div>
          {{
            endProduct ? row.sendTime.slice(0, 10) : row.createTime.slice(0, 10)
          }}
        </div>
      </div>
    </div>
    <slot name="special" />
  </div>
</template>

<script setup>
import { ref, computed, useSlots } from "vue";

const emits = defineEmits(["selectionChange", "handleEdit"]);

const slots = useSlots();

const props = defineProps({
  row: {},
  multipleSelection: {},
  nodeShow: {},
  useCheckbox: {
    type: Boolean,
    default: true
  },
  special: {},
  showCreateUserName: {},
  endProduct: {},
  nameKey: {
    type: String,
    default: "createUserName"
  }
});

const onTitleClick = () => {
  emits("handleEdit");
};

const onCheckboxClick = () => {
  if (!props.multipleSelection?.some(item => item === props.row)) {
    emits("selectionChange", [...props.multipleSelection, props.row]);
  } else {
    emits(
      "selectionChange",
      props.multipleSelection.filter(item => item !== props.row)
    );
  }
};
</script>
<style lang="scss" scoped>
.mobile_item {
  .item_top {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
    }

    .title_div {
      flex: 1;
      font-weight: 600;
      font-size: 15px;
      color: #333333;
      line-height: 22px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .node_div {
      height: 20px;
      border-radius: 2px;
      border: 1px solid #024ac2;
      padding: 0 4px;
      font-size: 12px;
      color: #024ac2;
      line-height: 18px;
    }

    .checkbox_div {
      width: 18px;
      height: 18px;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .item_bottom {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;

    .info_left {
      display: flex;
      gap: 4px;

      .slot_div {
        padding: 2px 4px;
        background: #f8f9f9;
        border-radius: 2px;
        font-size: 12px;
        color: #333333;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        :deep(.el-badge) {
          margin-top: 4px;
        }

        :deep(.el-icon) {
          display: block;
        }
      }
    }

    .info_right {
      display: flex;
      gap: 8px;
      font-size: 12px;
      color: #999999;
      line-height: 16px;
      align-items: center;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>

<template>
  <div class="material_upload" @click="tapUploadFile">
    <slot>
      <!-- <div class="bg"> -->
      <!-- <el-icon size="26px" color="#333333">
          <Plus />
        </el-icon>
        <p>图片/视频</p> -->
      <el-button type="primary">本地上传</el-button>
      <!-- </div> -->
    </slot>
  </div>
</template>

<script setup>
import { selectFiles } from "@/views/aiPoster/utils/utils";
import { ElMessage } from "element-plus";
import Oss from "@/utils/videoOss";
import { compressImage, dataURLtoFile, getUUID } from "@/utils/utils";
import { ref } from "vue";

const emits = defineEmits(["change", "getLength"]);

const props = defineProps({
  multiple: {
    type: Boolean,
    value: true
  },
  isTemporary: {
    type: Boolean,
    value: false
  },
  limit: {
    type: Number
  },
  modelType: {
    type: Number
  }
});

const createUploadQueue = (fileRow, type) => {
  const oss = new Oss({
    type: "media",
    mediaType: type
  });
  const fileInfo = ref({
    id: getUUID(),
    progress: 0,
    name: fileRow.name,
    file: "", // fileRow
    status: "1", // 1 上传中 2上传失败 3取消 4 完成  // 5转码失败
    oss: oss,
    url: "",
    fileType: type,
    decoding: false,
    decodingComplete: true,
    materialInfo: {},
    jobId: ""
  });

  // if (type === "video") {
  //   let name = fileRow.name.split(".");
  //   let lastName = name[name.length - 1].toLowerCase();
  //   const decoding = lastName !== "mp4";
  //   fileInfo.value.decoding = decoding;
  //   fileInfo.value.decodingComplete = !decoding;
  // }

  oss
    .uploadFile(fileRow, "media", true, percentage => {
      fileInfo.value.progress = Math.ceil(percentage * 100);
    })
    .then(res => {
      console.log(res, "图片或者音频上传返回");
      emits("change", {
        ...res,
        fileName: fileRow.name.split(".")[0],
        isTemporary: props.isTemporary
      });
    })
    .catch(() => {
      fileInfo.value.status = "2";
    });

  return fileInfo.value;
};

const tapUploadFile = () => {
  const imgType = ["png", "jpg", "jpeg", "gif"];
  const audioType = ["mp3"];
  const acceptList = props.modelType === 0 ? [...imgType] : [...audioType];
  selectFiles({
    accept: acceptList
      .map(item => {
        return `.${item}`;
      })
      .join(","),
    multiple: props.multiple,
    limit: props.limit
  }).then(async files => {
    if (!files.length) {
      return;
    }

    const typeArr = Array.from(files).map(item =>
      item.name.substring(item.name.lastIndexOf(".") + 1)
    );

    if (typeArr.every(item => !acceptList.includes(item))) {
      return ElMessage.error(`请上传${acceptList.join(",")}格式文件`);
    }
    console.log(typeArr, "typeArr");

    const imgList = [];
    const audioList = [];

    Array.from(files).forEach(item => {
      let name = item.name.split(".");
      let lastName = name[name.length - 1].toLowerCase();
      if (imgType.includes(lastName)) {
        if (item.size / 1024 / 1024 > 5) {
          ElMessage.error("图片不能大于5M");
        } else {
          imgList.push(item);
        }
      }

      if (audioType.includes(lastName)) {
        if (item.size / 1024 / 1024 > 100) {
          ElMessage.error("音频不能大于100M");
        } else {
          audioList.push(item);
        }
      }
    });
    console.log(files.length, "files.length");
    emits(
      "getLength",
      props.modelType === 0 ? imgList.length : audioList.length
    );

    if (imgList.length) {
      for (const item of imgList) {
        if (item.size / 1024 / 1024 > 20) {
          let name = item.name.split(".");
          let lastName = name[name.length - 1].toLowerCase();
          const blob = URL.createObjectURL(item);
          const img = await compressImage(blob, 1500, lastName);
          const newFile = dataURLtoFile(img, item.name || "img");
          const task = createUploadQueue(newFile, "IMAGE");
          // emits("change", task, files.length);
        } else {
          const task = createUploadQueue(item, "IMAGE");
          // emits("change", task, files.length);
        }
      }
    }

    if (audioList.length) {
      audioList.forEach(item => {
        const task = createUploadQueue(item, "AUDIO");
      });
    }
  });
};
</script>

<style scoped lang="scss">
.material_upload {
  cursor: pointer;
  // width: 100%;
  height: 100%;

  .bg {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: 1px solid #f0f0f0;
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 18px;
      margin-top: 5px;
    }
  }
}
</style>

<template>
  <div :class="{ orderBtn: true, orderBtn_m: isMobile }">
    <div class="leftBtn">
      <div style="margin-right: 12px" v-if="slots.left">
        <slot name="left" />
      </div>
      <el-button
        :type="item.type"
        :icon="needIcon && item.icon"
        :disabled="!disable"
        v-for="item in orderBtnList"
        :key="item.value"
        @click="handleClick(item.value)"
        link
      >
        <span :style="{ color: color ? color : undefined }">
          {{ item.name }}
        </span>
      </el-button>
    </div>
    <div v-if="slots.right"><slot name="right" /></div>
  </div>
</template>

<script setup>
import { watch, ref, useSlots } from "vue";
import { isMobile } from "@/utils/index";
const slots = useSlots();
const props = defineProps({
  orderHandle: {
    type: Function
  },
  openRemove: {
    type: Boolean
  },
  disable: {
    type: Boolean
  },
  needIcon: {
    type: Boolean,
    default: true
  },
  color: {
    type: Boolean
  },
  openMove: {
    type: Boolean,
    default: true
  }
});

const initArr = [
  { name: "置顶", type: "", icon: "CaretTop", value: 2 },
  { name: "置底", type: "", icon: "CaretBottom", value: 3 },
  { name: "上移", type: "", icon: "ArrowUp", value: 0 },
  { name: "下移", type: "", icon: "ArrowDown", value: 1 }
];

const orderBtnList = ref(initArr);

const handleClick = type => {
  props.orderHandle && props.orderHandle(type);
};

watch(
  () => props.openRemove,
  newVal => {
    if (newVal) {
      orderBtnList.value = [
        ...orderBtnList.value,
        {
          name: "移除",
          type: "",
          icon: "Delete",
          value: 4
        }
      ];
    }
  },
  { immediate: true }
);

watch(
  () => props.openMove,
  newVal => {
    if (newVal) {
      orderBtnList.value = [...initArr, ...orderBtnList.value];
    } else {
      orderBtnList.value = orderBtnList.value.filter(item => item.value === 4);
    }
  }
);
</script>

<style lang="scss">
.orderBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .leftBtn {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }
}

.orderBtn_m {
  .el-button {
    margin-left: 8px !important;
  }
}
</style>

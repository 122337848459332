<template>
  <div class="mobile_search">
    <div class="search_div" @click="drawer = true" :style="{ width: width }">
      <div :style="{ color: inpVal ? '#333333' : undefined }">
        {{ inpVal || "搜索稿件" }}
      </div>
      <img src="../../assets/img/icon_18x18_search_333333@2x.png" />
    </div>

    <el-drawer
      v-model="drawer"
      class="mobile_search_drawer"
      direction="btt"
      :size="size"
      :show-close="false"
      :with-header="false"
      :close-on-click-modal="false"
      :append-to="appendTo"
      :modal="false"
    >
      <template #default>
        <div class="input_div">
          <form
            style="flex: 1"
            action="javascript:return true"
            @submit.prevent="onImgClick"
          >
            <el-input
              ref="inputRef"
              :placeholder="'输入关键词 或 #ID回车'"
              v-model="inpVal"
              type="search"
              @input="onChange"
            >
              <template #suffix>
                <img
                  @click="onImgClick"
                  src="../../assets/img/icon_18x18_search_333333@2x.png"
                />
              </template>
            </el-input>
          </form>
          <div class="cance_div" @click="drawer = false">取消</div>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onBeforeUnmount } from "vue";

const paiData = ref([]);
const emit = defineEmits(["update:searchValue", "onSearch"]);

const props = defineProps({
  searchValue: {},
  width: {}
});

const drawer = ref(false);

const appendTo = ref();
const size = ref();
const inpVal = ref("");
const inputRef = ref();
const screenHeight = ref(0);

const onChange = e => {
  console.log(e, "inpVal.value");
  emit("update:searchValue", e);
};

const onImgClick = () => {
  emit("onSearch");
  drawer.value = false;
};

const handleResize = () => {
  let nowHeight = document.body.offsetHeight;
  console.log(nowHeight, "nowHeight");
  if (nowHeight < screenHeight.value) {
    size.value = size.value - (screenHeight.value - nowHeight);
  } else {
    appendTo.value = document.getElementsByClassName("mobile-content")[0];
    size.value =
      window.innerHeight - appendTo.value.getBoundingClientRect()?.top;
  }
};

watch(
  () => props.searchValue,
  val => {
    inpVal.value = val;
  }
);

watch(
  () => drawer.value,
  val => {
    if (val) {
      setTimeout(() => {
        console.log(111);
        // inputRef.value.focus();
      }, 300);
    }
  }
);

onMounted(() => {
  appendTo.value = document.getElementsByClassName("mobile-content")[0];
  size.value = window.innerHeight - appendTo.value.getBoundingClientRect()?.top;
  // 获取视图原始高度
  screenHeight.value = document.body.offsetHeight;
  console.log(screenHeight.value, "screenHeight");
  // 为window绑定resize事件
  window.addEventListener("resize", handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});
</script>
<style lang="scss" scoped>
.mobile_search {
  & > :nth-child(2) {
    position: absolute !important;
  }

  .search_div {
    width: 99px;
    height: 32px;
    border-radius: 2px;
    border: 1px solid #cccccc;
    display: flex;
    padding: 7px 8px;
    align-items: center;
    justify-content: center;
    gap: 6px;

    & > div {
      flex: 1;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      line-height: 17px;
      white-space: nowrap;
    }

    img {
      width: 18px;
      height: 18px;
    }
  }

  :deep(.el-drawer__body) {
    padding: 16px 12px;

    .input_div {
      display: flex;
      gap: 8px;

      .form {
        flex: 1;
      }

      .el-input {
        width: 100%;

        img {
          width: 18px;
          height: 18px;
        }
      }

      .cance_div {
        width: 52px;
        height: 32px;
        background: #f8f9f9;
        border-radius: 2px;
        font-size: 14px;
        color: #024ac2;
        line-height: 32px;
        text-align: center;
      }
    }
  }
}
</style>

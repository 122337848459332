<template>
  <div class="mobile_create">
    <img
      class="mobile_create_btn"
      @click="drawer = true"
      src="../../assets/img/icon_42x42_addfloat@2x.png"
    />

    <el-drawer
      v-model="drawer"
      direction="btt"
      :show-close="false"
      :with-header="false"
      size="240px"
    >
      <template #default>
        <div class="title_div">
          新建稿件
          <div class="close_div" @click="drawer = false">取消</div>
        </div>
        <el-row :gutter="7">
          <el-col :span="12" v-for="item in addList" :key="item.value">
            <div class="create_item" @click="onClick(item.value)">
              <img
                v-if="item.value == 0"
                src="../../assets/img/icon_24x24_tuwen@2x.png"
              />
              <img
                v-if="item.value == 1"
                src="../../assets/img/icon_24x24_shipin.png"
              />
              <img
                v-if="item.value == 2"
                src="../../assets/img/icon_24x24_shipinlive@2x.png"
              />
              <img
                v-if="item.value == 3"
                src="../../assets/img/icon_24x24_wailian@2x.png"
              />
              <img
                v-if="item.value == 4"
                src="../../assets/img/icon_24x24_tuwenlive@2x.png"
              />
              <img
                v-if="item.value == 5"
                src="../../assets/img/icon_24x24_tuji@2x.png"
              />
              {{ item.label }}
            </div>
          </el-col>
        </el-row>
      </template>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { ElMessage } from "element-plus";

const paiData = ref([]);
const emit = defineEmits(["onCreateClick"]);

const props = defineProps({
  listType: {}
});
const addList =
  props.listType === 1
    ? [
        { label: "图文稿件", value: "0" },
        { label: "图片稿件", value: "5" }
      ]
    : [
        { label: "图文稿件", value: "0" },
        { label: "视频稿件", value: "1" },
        { label: "视频直播", value: "2" },
        { label: "外链稿件", value: "3" },
        { label: "图文直播", value: "4" },
        { label: "图集稿件", value: "5" }
      ];
const drawer = ref(false);

const onClick = val => {
  emit("onCreateClick", val);
  drawer.value = false;
};
</script>
<style lang="scss" scoped>
.mobile_create {
  .mobile_create_btn {
    position: fixed;
    bottom: 58px;
    right: 16px;
    width: 42px;
    height: 42px;
  }

  :deep(.el-drawer__body) {
    padding: 16px 12px;

    .title_div {
      font-weight: 600;
      font-size: 16px;
      color: #333333;
      line-height: 24px;
      text-align: center;
      position: relative;
      overflow: hidden;

      .close_div {
        position: absolute;
        right: 0;
        top: 0;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 24px;
      }
    }

    .create_item {
      margin-top: 8px;
      height: 40px;
      background: #f8f9f9;
      border-radius: 2px;
      display: flex;
      font-size: 14px;
      color: #024ac2;
      align-items: center;
      justify-content: center;

      img {
        width: 24px;
        height: 24px;
        margin-right: 2px;
      }
    }
  }
}
</style>

<template>
  <div class="QRcode_com">
    <el-dialog
      v-model="dialogVisible"
      title=""
      :width="isMobile ? '311px' : '416px'"
      top="100px"
      @close="handleClose"
      destroy-on-close
      :close-on-click-modal="false"
      append-to-body
    >
      <div :class="{ QRcode_div: true, qr_mobile: isMobile }">
        <div class="left_div">
          <p v-if="!isMobile">扫码查看稿件</p>
          <img :src="codeImg" />
          <div @click.stop="onDownload" style="cursor: pointer" v-if="!isIOS()">
            下载二维码
          </div>
        </div>
        <div class="right_div">
          <div class="url_div" :title="visible">{{ visible }}</div>
          <div class="btns_div" v-if="!isMobile">
            <div @click.stop="onCopy([visible])">复制网址</div>
            <div @click.stop="onOpen">打开网址</div>
          </div>
        </div>
        <div @click.stop="onCopy([visible])" class="copy_div" v-if="isMobile">
          复制网址
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";
import { CustomQRCode } from "@/components/CustomQRCode";
// import { onCopy } from "../tool.js";
import { ElMessage } from "element-plus";
import { isMobile } from "@/utils/index";

const emits = defineEmits(["update:visible"]);
const props = defineProps({
  visible: {
    type: [String]
  },
  nodeId: {}
});

const dialogVisible = ref(true);
const codeImg = ref("");

const handleClose = () => {
  emits("update:visible", false);
};

const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  );
};
const onOpen = () => {
  window.open(props.visible);
};

function downloadImage(base64Data, fileName) {
  const img = new Image();

  img.onload = function () {
    const canvas = document.createElement("canvas");

    canvas.width = img.width;

    canvas.height = img.height;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(img, 0, 0);

    // 对于较新的iOS版本，可以直接使用toBlob

    if (canvas.toBlob) {
      canvas.toBlob(function (blob) {
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");

        link.href = url;

        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        URL.revokeObjectURL(url);
      });
    } else {
      // 对于不支持toBlob的浏览器，使用替代方法

      const dataURL = canvas.toDataURL("image/png");

      const link = document.createElement("a");

      link.href = dataURL;

      link.download = fileName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    }
  };

  img.src = base64Data;
}

const onDownload = () => {
  downloadImage(codeImg.value, `${Date.now()}.png`);
};

const onCopy = val => {
  const input = document.createElement("input"); // js创建一个input输入框
  input.value = val.join(","); // 将需要复制的文本赋值到创建的input输入框中
  document.body.appendChild(input); // 将输入框暂时创建到实例里面
  input.select(); // 选中输入框中的内容
  document.execCommand("Copy"); // 执行复制操作
  document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
  ElMessage.success("复制成功");
};

watch(
  () => props.visible,
  newVal => {
    dialogVisible.value = !!newVal;
    if (newVal) {
      new CustomQRCode({
        text: newVal,
        size: 400,
        margin: 20,
        colorDark: "rgb(3 3 3)",
        logoImage: "",
        colorLight: "rgba(255, 255, 255, 1)",
        logoMargin: 6,
        logoCornerRadius: 0,
        logoScale: 0.25
      })
        .draw()
        .then((dataUrl: any) => {
          codeImg.value = dataUrl;
        });
    }
  },
  {
    immediate: true
  }
);
</script>
<style lang="scss" scoped>
.QRcode_com {
  width: 100%;

  :deep(.el-dialog__body) {
    padding: 0 30px 30px;
  }

  :deep(.el-dialog__header) {
    padding: 20px 30px;
    height: 62px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 22px;
  }
}

.QRcode_div {
  display: flex;

  .left_div {
    width: 100px;
    margin-right: 24px;

    p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
      margin-bottom: 7px;
      text-align: center;
    }

    img {
      width: 100px;
      height: 100px;
    }

    div {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      margin-top: 10px;
      text-align: center;
    }
  }

  .right_div {
    height: 103px;
    background: #f8f8f8;
    flex: 1;
    margin-top: 23px;
    padding: 30px 20px 0;

    .url_div {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      width: 192px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .btns_div {
      display: flex;
      margin-top: 11px;

      & > div {
        flex: 1;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.qr_mobile {
  display: block;

  .left_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: auto;
    margin-right: 0;

    img {
      width: 120px;
      height: 120px;
    }

    & > div {
      width: 120px;
      height: 32px;
      border-radius: 2px;
      border: 1px solid #024ac2;
      font-weight: 400;
      font-size: 14px;
      color: #024ac2;
      line-height: 30px;
    }
  }

  .right_div {
    padding: 12px;
    height: auto;

    .url_div {
      width: auto;
      white-space: unset;
    }
  }

  .copy_div {
    width: 120px;
    height: 32px;
    border-radius: 2px;
    border: 1px solid #024ac2;
    font-weight: 400;
    font-size: 14px;
    color: #024ac2;
    line-height: 30px;
    margin: 12px auto;
    text-align: center;
  }
}
</style>

import App from "./App.vue";
import router from "./router";
import { setupStore } from "@/store";
import ElementPlus from "element-plus";
import { getServerConfig } from "./config";
import { createApp, Directive } from "vue";
import { useI18n } from "@/plugins/i18n";
import { MotionPlugin } from "@vueuse/motion";
import PaiTable from "./components/PaiTable/index.vue";
import PaiSelect from "./components/PaiSelect/index.vue";
import OrderBtn from "./components/OrderBtn/index.vue";
import SelectWords from "./components/SelectWords/index.vue";
import CopyBtn from "./components/CopyBtn/index.vue";
import SelectImg from "./components/SelectImg/index.vue";
import ArticlePreview from "./components/ArticlePreview/index.vue";
import MobileList from "./components/MobileList/index.vue";
import MobileItem from "./components/MobileItem/index.vue";
import MobileCreate from "./components/MobileCreate/index.vue";
import MobileBtn from "./components/MobileBtn/index.vue";
import MobileSearch from "./components/MobileSearch/index.vue";
import { VueMasonryPlugin } from "vue-masonry";

import { useEcharts } from "@/plugins/echarts";
import { injectResponsiveStorage } from "@/utils/responsive";
// 导入字体图标
import "./assets/iconfont/iconfont.css";
// 导入右侧菜单图标
import "./assets/iconfontMenu/iconfont.css";
import "animate.css";
// 引入重置样式
import "./style/reset.scss";
// 导入公共样式
import "./style/index.scss";
import "./style/tailwind.css";
import "element-plus/dist/index.css";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import VMdEditor from "@kangc/v-md-editor";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import "@kangc/v-md-editor/lib/theme/style/github.css";

// highlightjs
import hljs from "highlight.js";

import { ElMessageBox } from "element-plus";
import VConsole from "vconsole";
import { loadEnv } from "@build/index";
import { isMobile } from "@/utils/index";
import FastClick from "fastclick";
console.log(FastClick, "FastClick");

if (isMobile) {
  import("@/style/theme.scss");

  setTimeout(() => {
    if ("addEventListener" in document) {
      document.addEventListener(
        "DOMContentLoaded",
        function () {
          FastClick.attach(document.body);
        },
        false
      );
    }
  }, 10);
}

const { VITE_APP_COOKIE_NAME } = loadEnv();

if (["dev", "test"].includes(VITE_APP_COOKIE_NAME)) {
  new VConsole();
}

VMdEditor.use(githubTheme, {
  Hljs: hljs
});

const app = createApp(App);

app.use(VueMasonryPlugin);

// 自定义指令
import * as directives from "@/directives";

Object.keys(directives).forEach(key => {
  app.directive(key, (directives as { [key: string]: Directive })[key]);
});

// 全局注册`@iconify/vue`图标库
import { IconifyIconOffline, FontIcon } from "./components/ReIcon";

app.component("IconifyIconOffline", IconifyIconOffline);
app.component("FontIcon", FontIcon);
app.component("PaiTable", PaiTable);
app.component("PaiSelect", PaiSelect);
app.component("OrderBtn", OrderBtn);
app.component("SelectWords", SelectWords);
app.component("CopyBtn", CopyBtn);
app.component("SelectImg", SelectImg);
app.component("ArticlePreview", ArticlePreview);
app.component("MobileList", MobileList);
app.component("MobileItem", MobileItem);
app.component("MobileCreate", MobileCreate);
app.component("MobileBtn", MobileBtn);
app.component("MobileSearch", MobileSearch);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(VMdEditor);

window.addEventListener(
  "error",
  event => {
    // 过滤js error
    const target = event.target || event.srcElement;
    const isElementTarget =
      target instanceof HTMLScriptElement ||
      target instanceof HTMLLinkElement ||
      target instanceof HTMLImageElement;
    if (!isElementTarget) return false;
    // @ts-ignore
    const url = target?.src || target?.href || "";
    const isJSFile = /.*\.js$/;
    if (url.indexOf(location.origin) === 0 && isJSFile.test(url)) {
      ElMessageBox.confirm(
        "页面有部署，请点击确定按钮，会刷新后访问新页面",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false
        }
      )
        .then(async () => {
          location.reload();
        })
        .catch(() => {});
    }
  },
  true
);

getServerConfig(app).then(async config => {
  injectResponsiveStorage(app, config);
  app.use(router);
  await router.isReady();
  setupStore(app);
  app
    .use(MotionPlugin)
    .use(useI18n)
    .use(ElementPlus)
    .use(useEcharts)
    .use(VueMasonryPlugin);
  app.mount("#app");
});

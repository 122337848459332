import { useMultiTagsStore } from "@/store/modules/multiTags";
import { usePermissionStoreHook } from "@/store/modules/permission";
import { storageLocal } from "@pureadmin/utils";
import { ElMessage } from "element-plus";
import dayjs from "dayjs";
import { loadEnv } from "@build/index";

const { VITE_APP_PAPER_PREVIEWWAP } = loadEnv();

//   有打开的标签true
export const noOpen = (name, type?: boolean) => {
  const flag = (useMultiTagsStore() as any).multiTags.some(
    (item: any) => item.name && item.name === name
  );
  if (flag && !type) {
    ElMessage.warning("已有打开的标签");
  }
  return !flag;
};

// 获取权限
export const filterPower = key => {
  const allPower = storageLocal.getItem<string[]>("permission") || [];
  return allPower.includes(key);
};

// 获取权限
export const filterAuths = val => {
  if (typeof val === "string") {
    return usePermissionStoreHook().buttonAuth.includes(val);
  }
  if (Array.isArray(val)) {
    const res = {
      all: true
    };
    let hasNum = 0;
    val.forEach(item => {
      const status = usePermissionStoreHook().buttonAuth.includes(item);
      res[`${item}`] = status;
      if (!status) {
        res.all = false;
      } else {
        hasNum += 1;
      }
    });
    return {
      ...res,
      some: hasNum > 0
    };
  }
  return false;
};

// base64转file
export function dataURLtoFile(dataurl, fileName) {
  try {
    const arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);

    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const bolbObj: any = new Blob([u8arr], { type: mime });

    bolbObj.lastModifiedDate = new Date();
    bolbObj.name = `${Date.now()}.${fileName}`;

    const file = new File([bolbObj], `${Date.now()}.${fileName}`, {
      type: mime,
      lastModified: Date.now()
    });
    return file;
  } catch (e) {
    console.log(e);
  }
}

// 将秒处理成HH：mm:ss
export function changeTimeType(num, showH = true) {
  if (num === null) return "00:00";
  let Time = null;
  let branch = null;
  let second = null;
  if (num > 3600) {
    Time = Math.floor(num / 3600);
    const surplus = num - Time * 3600;
    if (surplus > 60) {
      branch = Math.floor(surplus / 60);
      second = surplus - branch * 60;
    } else {
      second = surplus;
    }
  } else if (num > 60) {
    branch = Math.floor(num / 60);
    second = num - branch * 60;
  } else if (num === 60) {
    return "01:00";
  } else {
    second = num;
  }
  if (Time && Time < 10) Time = `0${Time}`;
  if (branch && branch < 10) branch = `0${branch}`;
  if (second < 10) second = `0${second}`;
  return `${Time ? `${Time}:` : showH ? "00:" : ""}${
    branch ? `${branch}:` : "00:"
  }${second}`;
}

// 处理多维数组，参数一传入数组，参数2回调函数，参数3层级
export function handleHeavy(arr, callback, childrenKey = "children", level) {
  Array.isArray(arr) &&
    arr.forEach(item => {
      callback && callback(item, level);
      if (Array.isArray(item[childrenKey])) {
        handleHeavy(item[childrenKey], callback, childrenKey, level + 1);
      }
    });
}

// 判断当前设备
export const isMobileFn = () => {
  const userAgent = navigator.userAgent;
  const mobileKeywords = [
    "Android",
    "iPhone",
    "iPad",
    "Windows Phone",
    "Mobile"
  ];
  return mobileKeywords.some(keyword => userAgent.includes(keyword));
};

// 日期处理
export const specialData = times => {
  console.log("日期", times);
  const currentTime = dayjs();
  const targetTime = dayjs().hour(11); // 获取今天的开始时间（即今天的00:00:00）
  const startOfToday = dayjs().startOf("day"); // 获取今天的结束时间（即今天的23:59:59）
  const endOfToday = dayjs().endOf("day");
  if (currentTime.isAfter(startOfToday) && currentTime.isBefore(endOfToday)) {
    if (currentTime.isBefore(targetTime)) {
      console.log("当前日期在11之前");
      return times;
    } else {
      console.log("当前日期在11之后");
      return dayjs(times).add(1, "day").format("YYYY-MM-DD");
    }
  } else {
    return times;
  }
};

export const perviewH5 = (contId, contType, link, data, time) => {
  console.log(contId, contType, link, data, time);

  const urlEn = {
    1: "/videoDetail",
    2: "/liveDetail",
    4: "/liveDetail",
    5: "/atlasDetail",
    9: "/topicDetail",
    10: "/activityDetail",
    12: "/topicDetail/02",
    13: "/topicDetail/01",
    111: "/finance",
    112: "/reading"
  };

  const url = urlEn[contType] || "/commonDetail";
  // let url = "commonDetail";
  // switch (contType) {
  //   case 0:
  //     url = "commonDetail";
  //     break;

  //   case 1:
  //     url = "videoDetail";
  //     break;

  //   case 2:
  //     url = "liveDetail";
  //     break;

  //   case 3:
  //     url = link;
  //     break;

  //   case 4:
  //     url = "liveDetail";
  //     break;

  //   case 5:
  //     url = "atlasDetail";
  //     break;

  //   case 8:
  //     url = link;
  //     break;

  //   case 9:
  //     url = "topicDetail";
  //     break;
  //   case 10:
  //     url = "activityDetail";
  //     break;

  //   default:
  //     url = "commonDetail";
  //     break;
  // }
  // if (contType == 3 || contType == 8) {
  //   window.open(url);
  // } else {
  //   window.open(

  //   );
  // }
  if (contType == 111 || contType == 112) {
    // 财经日历预览
    console.log(time, "👑");
    const year = time[0];
    const month = time[1];
    const date = time[2];
    return `${VITE_APP_PAPER_PREVIEWWAP}${url}?sign=${data.sign}&timestamp=${data.timestamp}&year=${year}&month=${month}&day=${date}`;
  } else {
    return `${VITE_APP_PAPER_PREVIEWWAP}${url}/${contId}?sign=${data.sign}&timestamp=${data.timestamp}`;
  }
};

export const isMobile =
  /Mobile/i.test(navigator.userAgent) ||
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const generateRandomString = length => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

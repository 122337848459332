<template>
  <div class="mobile_btn" v-show="drawer">
    <slot />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { ElMessage } from "element-plus";

const paiData = ref([]);
const emit = defineEmits(["onCreateClick"]);

const props = defineProps({
  multipleSelection: {}
});

const drawer = ref(false);

watch(
  () => props.multipleSelection,
  val => {
    if (Array.isArray(val) && val.length) {
      drawer.value = true;
    } else {
      drawer.value = false;
    }
  },
  { deep: true }
);
</script>
<style lang="scss" scoped>
.mobile_btn {
  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 100;
  width: 100vw;
  padding: 8px 8px 34px 8px;
  background: #ffffff;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.12);

  :deep(.el-button) {
    background-color: #f8f9f9;
    border: none;
    color: #024ac2;
  }

  :deep(.el-button:focus) {
    background-color: #f8f9f9;
    border: none;
    color: #024ac2;
  }

  :deep(.el-button:hover) {
    background-color: #f8f9f9;
    border: none;
    color: #024ac2;
  }

  :deep(.el-button:focus-visible) {
    background-color: #f8f9f9;
    border: none;
    color: #024ac2;
  }

  :deep(.is-disabled) {
    background-color: #f8f9f9;
    border: none;
    color: #cccccc;
  }

  :deep(.is-disabled:hover) {
    background-color: #f8f9f9;
    border: none;
    color: #cccccc;
  }
}
</style>

<template>
  <div>
    <el-dialog
      class="video-dialog"
      v-model="videoVisible"
      title="上传视频"
      :width="isMobile ? '90%' : '700px'"
      :before-close="handleClose"
      :close-on-click-modal="false"
      destroy-on-close
      append-to-body
      modal-class="modal_style"
      :append-to="appendTo ? `#${appendTo}` : undefined"
    >
      <el-form
        ref="ruleFormRef"
        class="video-forms"
        :model="ruleForm"
        :rules="rules"
        size="default"
        label-width="120px"
        label-position="top"
      >
        <el-form-item label="视频封面" prop="coverUrl">
          <div class="video-cover">
            <img :src="ruleForm.coverUrl" v-if="ruleForm.coverUrl" />
            <div class="no_img_layout" v-else @click.stop="handleReplace">
              <!-- <img src="../img/icon_36x36_add_333333@2x.png" /> -->
              <div class="plus_div" />
              <div>上传图片</div>
            </div>

            <p>
              <span class="video-replace" @click="handleReplace">替换</span>
              <span
                class="video-delete"
                :style="{
                  opacity: ruleForm.coverUrl ? 1 : 0.6
                }"
                @click="handleDeleteCover"
                >删除</span
              >
              <!-- <span
                class="video-cut"
                :style="{
                  opacity: ruleForm.coverUrl ? 1 : 0.6
                }"
                @click="handleCropper"
                >裁剪</span
              > -->
            </p>
          </div>
        </el-form-item>
        <el-form-item label="视频标题" prop="title" size="default">
          <el-input
            v-model="ruleForm.title"
            placeholder="请输入视频标题"
            maxlength="28"
            show-word-limit
            type="text"
            onkeydown="if (event.keyCode===13){return false;}"
          />
        </el-form-item>
        <template v-if="ruleForm.autoClip">
          <!-- <el-form-item label="" class="video-nobottom">
            <div>
              <el-radio
                label="1"
                v-model="ruleForm.selected"
                @click.prevent="handleSystempack"
                >系统自动加栏包</el-radio
              >
              <span class="video-lan-tips"
                >勾选并保存后，将自动识别横视频和竖视频，并添加对应的栏目栏包</span
              >
            </div>
          </el-form-item> -->
          <el-form-item
            label=""
            v-if="ruleForm.selected === '1'"
            class="video-nobottom"
          >
            <div>
              <div class="video-pianwei">
                <span>片尾设置</span>
                <el-radio-group v-model="ruleForm.videoEditReq.fileTail">
                  <el-radio :label="1">参与人员名单</el-radio>
                  <el-radio :label="2">固定片尾</el-radio>
                </el-radio-group>
              </div>
              <span
                class="video-lan-user-tips"
                v-if="ruleForm.videoEditReq.fileTail === 1"
                >仅横视频支持显示参与人员名单</span
              >
            </div>
          </el-form-item>
          <el-form-item
            label=""
            v-if="
              ruleForm.selected === '1' && ruleForm.videoEditReq.fileTail === 1
            "
          >
            <div>
              <div class="video-users">
                <span>人员名单</span>
                <div>
                  <div>
                    <div
                      v-for="(item, index) in ruleForm.videoEditReq.nameList"
                      :key="index"
                      class="video-users-list"
                    >
                      <el-input
                        type="text"
                        v-model="item.title"
                        placeholder="职务"
                        maxlength="6"
                        show-word-limit
                        style="width: 200px"
                      />
                      <el-input
                        type="text"
                        v-model="item.name"
                        placeholder="姓名"
                        maxlength="9"
                        show-word-limit
                        style="width: 200px"
                      />
                      <span @click="hanldeDeleteUser(index)">删除</span>
                    </div>
                  </div>
                  <button @click="e => handleAdduser(e)">
                    <i />{{
                      ruleForm.videoEditReq.nameList.length ? "继续" : ""
                    }}添加
                  </button>
                </div>
              </div>
            </div>
          </el-form-item>
        </template>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="default" @click="handleClose">取消</el-button>
          <el-button
            size="default"
            type="primary"
            @click="submitForm(ruleFormRef)"
          >
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 裁剪 -->
    <Cropper
      v-model:visible="cropperVisible"
      :cropperUrl="cropperUrl"
      :aspectRatio="aspectRatio"
      @singleSuccess="singleSuccess"
    />

    <MaskCustom
      :visible="maskVisible"
      title="视频上传中……"
      :appendTo="appendTo"
    />
  </div>
</template>

<script setup>
import { ref, reactive, watch, nextTick } from "vue";
import { selectFiles } from "@/views/aiPoster/utils/utils";
import { ElMessageBox, ElMessage } from "element-plus";
import Cropper from "./com/Cropper";
import MaskCustom from "@/components/MaskCustom";
import Oss from "@/utils/videoOss";
import { getUUID } from "@/utils/utils";
import { postResource, getUploadStatus, checkVideo } from "@/api/upload";
import { loadEnv } from "@build/index";
import { isMobile } from "@/utils/index";

const { VITE_APP_PAPER_IMAGE } = loadEnv();

const picUrl =
  VITE_APP_PAPER_IMAGE + "/cnstock/static/default/kaiping_cover.png";
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  isTemporary: {
    type: Boolean,
    default: false
  },
  nodeId: {
    type: [Number, String]
  },
  aspectRatio: {
    type: Number,
    default: NaN
  },
  videoFile: {},
  appendTo: {
    type: String,
    default: undefined
  }
});

let loopStatus = null;

const emits = defineEmits(["update:visible", "handleOk"]);

const cropperUrl = ref("");

const cropperVisible = ref(false);

const videoVisible = ref(false);

const maskVisible = ref(false);

const normalUrl = ref("");

const ruleFormRef = ref(null);

const ruleForm = reactive({
  autoClip: true,
  coverUrl: "",
  size: 0,
  title: "",
  type: "VIDEO",
  url: "",
  videoEditReq: {
    channelId: props.nodeId,
    fileTail: 1,
    nameList: []
  }
});

const rules = reactive({
  title: [{ required: true, message: "请输入视频标题", trigger: "blur" }]
});

const checkVideoData = async nodeId => {
  const res = await checkVideo({
    nodeId
  });
  if (res.code === 200) {
    ruleForm.autoClip = res.data;
  }
};

const tapUploadFile = () => {
  const imgType = ["png", "jpg", "jpeg", "gif"];
  selectFiles({
    accept: [...imgType]
      .map(item => {
        return `.${item}`;
      })
      .join(","),
    multiple: false
  }).then(async files => {
    if (!files.length) {
      return;
    }

    if (files[0].size / 1024 / 1024 > 10) {
      ElMessage.error("图片不能大于10M");
    } else {
      createUploadQueue(files[0], "IMAGE");
    }
  });
};

const resetForm = () => {
  ruleForm.selected = "";
  ruleForm.coverUrl = "";
  normalUrl.value = "";
  ruleForm.url = "";
  ruleForm.title = "";
  ruleForm.videoEditReq.channelId = props.nodeId;
  ruleForm.videoEditReq.fileTail = 1;
  ruleForm.videoEditReq.nameList = [];
};

const createUploadQueue = (fileRow, type) => {
  const oss = new Oss({
    type: "media",
    mediaType: type
  });
  const fileInfo = ref({
    id: getUUID(),
    progress: 0,
    name: fileRow.name,
    file: "", // fileRow
    status: "1", // 1 上传中 2上传失败 3取消 4 完成  // 5转码失败
    oss: oss,
    url: "",
    fileType: type,
    decoding: false,
    decodingComplete: true,
    materialInfo: {},
    jobId: ""
  });

  if (type === "VIDEO") {
    let name = fileRow.name.split(".");
    let lastName = name[name.length - 1].toLowerCase();
    const decoding = lastName !== "mp4";
    fileInfo.value.decoding = decoding;
    fileInfo.value.decodingComplete = !decoding;
  }

  oss
    .uploadFile(fileRow, "media", true, percentage => {
      fileInfo.value.progress = Math.ceil(percentage * 100);
    })
    .then(res => {
      if (fileRow.size / 1024 / 1024 > 2500) {
        ElMessage.error("视频不能大于2.5G");
        return;
      }

      if (type === "VIDEO") {
        ruleForm.url = res.ossUrl;
        // ruleForm.coverUrl = `${res.ossUrl}?x-oss-process=video/snapshot,t_0,f_jpg,w_1280,h_0,m_fast,ar_auto`;
        normalUrl.value = "";
      } else {
        ruleForm.coverUrl = res.ossUrl;
        nextTick(() => {
          ruleFormRef.value.validateField("coverUrl");
        });
      }
      // if (ruleForm.coverUrl.indexOf("default_video_cover") !== -1) {
      //   ruleForm.coverUrl = "";
      // }

      nextTick(() => {
        if (type === "VIDEO") {
          postResource({
            ...ruleForm,
            isTemporary: props.isTemporary,
            fileName: res.fileName,
            coverUrl:
              ruleForm.coverUrl ||
              `${res.ossUrl}?x-oss-process=video/snapshot,t_0,f_jpg,w_1280,h_0,m_fast,ar_auto`
          }).then(data => {
            if (data.code === 200) {
              if (data.data.originSrc.indexOf(".mp4") === -1) {
                loopVideoStatus(data);
                loopStatus = setInterval(() => {
                  loopVideoStatus(data);
                }, 3000);
              } else {
                maskVisible.value = false;
                resetForm();
                emits("handleOk", {
                  ...data.data,
                  duration: data.data.duration,
                  originSrc: data.data.originSrc,
                  width: data.data.width,
                  height: data.data.height
                });
              }
            }
          });
        }
      });
    })
    .catch(() => {
      fileInfo.value.status = "2";
    });

  return fileInfo.value;
};

const loopVideoStatus = data => {
  getUploadStatus({
    mediaId: data.data.id
  }).then(result => {
    console.log(result, "🐷🐷🐷");
    if (result.data.finish) {
      clearInterval(loopStatus);
      maskVisible.value = false;
      resetForm();
      emits("handleOk", {
        ...data.data,
        duration: result.data.mediaDetail.media.duration,
        originSrc: result.data.mediaDetail.media.url,
        width: result.data.mediaDetail.media.width,
        height: result.data.mediaDetail.media.height
      });
    }
  });
};

const submitForm = async formEl => {
  if (!formEl) return;
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      maskVisible.value = true;
      await createUploadQueue(props.videoFile.raw, "VIDEO");
    } else {
      console.log("error submit!", fields);
    }
  });
};

//  替换封面图
const handleReplace = () => {
  tapUploadFile();
};

//  删除封面图，还原为原始的
const handleDeleteCover = () => {
  ruleForm.coverUrl = "";
  normalUrl.value = "";
};

//  裁剪封面图
const handleCropper = () => {
  if (!ruleForm.coverUrl) {
    return;
  }
  cropperUrl.value = normalUrl.value;
  nextTick(() => {
    cropperVisible.value = true;
  });
};

const singleSuccess = imgUrl => {
  ruleForm.coverUrl = imgUrl.ossUrl;
  cropperVisible.value = false;
};

const handleClose = () => {
  ElMessageBox.confirm("是否确认取消本地上传?", "", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    closeOnClickModal: false,
    closeOnPressEscape: false,
    appendTo: ".video-dialog",
    customClass: "video-mask-confim"
  })
    .then(() => {
      emits("update:visible", false);
      resetForm();
    })
    .catch(() => {});
};

const handleSystempack = () => {
  if (ruleForm.selected) {
    ruleForm.selected = "";
    ruleForm.autoClip = false;
  } else {
    ruleForm.selected = "1";
    ruleForm.autoClip = true;
  }
};

const handleAdduser = e => {
  e.preventDefault();
  e.stopPropagation();
  ruleForm.videoEditReq.nameList.push({
    title: "",
    name: ""
  });
};

const hanldeDeleteUser = index => {
  ruleForm.videoEditReq.nameList.splice(index, 1);
};

watch(
  () => props.visible,
  newVal => {
    videoVisible.value = newVal;
    if (newVal) {
      ruleForm.videoEditReq.channelId = props.nodeId;
      ruleForm.title = props.videoFile.name || "";
      // checkVideoData(props.nodeId);
    }
  }
);

watch(
  () => props.appendTo,
  newVal => {
    console.log(newVal, "newValnewValnewVal");
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
:deep(.video-dialog) {
  z-index: 999999999;

  .el-dialog__body {
    padding-top: 10px !important;
    font-size: 16px;
  }

  .el-dialog__header {
    font-weight: bold;
  }
}

.video-forms {
  :deep(.el-form-item__label) {
    font-weight: bold;
    font-size: 14px;
    color: #333;
  }

  :deep(.el-form-item) {
    --font-size: 14px;
    --el-form-label-font-size: var(--font-size);
    margin-bottom: 18px;
  }
}

.no_img_layout {
  width: 160px;
  height: 90px;
  border: 1px dashed #ccc;
  cursor: pointer;

  .plus_div {
    width: 36px;
    height: 36px;
    margin: 0 auto;
    background: url("./img/icon_36x36_add_333333@2x.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 10px;
  }

  div {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    margin-top: 5px;
    text-align: center;
  }
}

.video-cover {
  display: flex;
  align-items: center;
  line-height: 36px;

  img {
    width: 160px;
    height: 90px;
    object-fit: cover;
  }

  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    line-height: 30px;

    span {
      color: #409eff;
      cursor: pointer;
      font-size: 12px;
      padding-left: 18px;

      &.video-replace {
        background: url("./img/icon_change.png") no-repeat left center;
        background-size: 12px 12px;
      }

      &.video-delete {
        background: url("./img/icon_del.png") no-repeat left center;
        background-size: 12px 12px;
      }

      &.video-cut {
        background: url("./img/icon_cut.png") no-repeat left center;
        background-size: 12px 12px;
      }
    }
  }
}

.video-lan-tips {
  display: block;
  font-size: 12px;
  color: #999;
  margin-left: 20px;
  line-height: 10px;
  margin-bottom: 10px;
}

.video-pianwei {
  display: flex;
  align-items: center;

  span {
    margin-right: 20px;
    margin-left: 20px;
    color: #333;
    font-size: 14px;
  }
}

.video-users {
  display: flex;
  align-items: flex-start;

  span {
    margin-right: 20px;
    margin-left: 20px;
    color: #333;
    font-size: 14px;
  }

  button {
    width: 405px;
    height: 32px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #409eff;
    color: #409eff;
    line-height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      width: 12px;
      height: 12px;
      display: inline-block;
      background: url("./img/icon_add.png") no-repeat 1px center;
      background-size: 12px 12px;
      margin-right: 5px;
    }
  }

  &-list {
    margin-bottom: 10px;

    :deep(.el-input) {
      margin-right: 5px;
    }

    span {
      font-size: 14px;
      cursor: pointer;
      color: #409eff;
      background: url("./img/icon_del.png") no-repeat left center;
      background-size: 12px 12px;
      padding-left: 18px;
      margin-left: 10px;
    }
  }
}

.video-lan-user-tips {
  margin-left: 95px;
  font-size: 12px;
  color: #999;
  display: block;
  margin-top: -8px;
}

.video-nobottom {
  margin-bottom: 0;
}

:deep(.video-mask-confim) {
  .el-message-box__container {
    i {
      font-size: 18px !important;
    }
  }

  .el-message-box__message {
    padding-left: 22px;
  }
}
</style>

<template>
  <div class="select_words">
    <el-drawer
      v-model="dialogVisible"
      direction="btt"
      size="95%"
      :show-close="false"
      :with-header="false"
      :close-on-click-modal="false"
      v-if="isMobile"
    >
      <template #default>
        <div class="title_div_word">
          {{ title }}
          <div class="close_div" @click="handleClose">取消</div>
        </div>
        <div>
          <el-form
            :model="form"
            :inline="true"
            ref="formRef"
            class="form_mobile"
          >
            <el-form-item prop="keyword">
              <el-input
                v-model="form.keyword"
                placeholder="搜索稿件"
                clearable
                style="width: 100%"
              >
                <template #append>
                  <el-button :icon="Search" @click="loading = true" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              prop="keyword"
              v-if="props.isActivity"
              style="width: 100%"
            >
              <el-input
                v-model="form.activityKeyword"
                placeholder="请输入活动关键词搜索"
              />
            </el-form-item>

            <el-form-item prop="nodeId" v-if="!props.isActivity">
              <el-tree-select
                style="width: 100%"
                placeholder="全部栏目"
                v-model="form.nodeId"
                :data="columnTreeData"
                highlight-current
                check-strictly
                default-expand-all
                @change="loading = true"
              />
            </el-form-item>
          </el-form>

          <MobileList
            v-if="isMobile"
            v-model:loading="loading"
            ref="paiTableRef"
            :columns="columns"
            :reqFun="fn"
            height="calc(95vh - 116px)"
            :reqParams="{ isPublished: 1, ...form, contDbId: 0 }"
            selection
            isDalog
            @selection-change="val => (selectTable = val)"
          >
            <template #default="{ row }">
              <MobileItem
                :row="row"
                :special="true"
                :multipleSelection="selectTable"
                @selection-change="val => (selectTable = val)"
              >
                <template #contId>
                  <div class="tag_div">
                    <span>ID:</span><span>{{ row.contId }}</span>
                  </div>
                </template>
                <template #nodeName>
                  <div class="tag_div">
                    <span>栏目:</span><span>{{ row.nodeName }}</span>
                  </div>
                </template>
                <template #createUserName>
                  <div class="tag_div">
                    <span>作者:</span><span>{{ row.createUserName }}</span>
                  </div>
                </template>

                <template #special>
                  <div class="tag_div">
                    <span>发布时间:</span><span>{{ row.updateTime }}</span>
                  </div>
                  <div class="tag_div" style="position: relative">
                    <span>首次发布时间:</span>
                    <span>{{ row.firstPublishTime }}</span>
                    <ArticlePreview :row="row" class="preview_div">
                      预览
                    </ArticlePreview>
                  </div>
                </template>
              </MobileItem>
            </template>
          </MobileList>

          <div class="sum_div" @click="onSubmit">确定选择</div>
        </div>
      </template>
    </el-drawer>
    <el-dialog
      v-else
      v-model="dialogVisible"
      :title="title"
      :width="isMobile ? '90%' : '950px'"
      :before-close="handleClose"
      :style="isMobile ? 'height: 90vh' : 'height: 800px'"
      align-center
    >
      <PaiTable
        v-model:loading="loading"
        ref="paiTableRef"
        :columns="columns"
        :reqFun="fn"
        :reqParams="{ isPublished: 1, ...form, contDbId: 0 }"
        selection
        isDalog
        @selection-change="val => (selectTable = val)"
        style="height: 500px"
      >
        <template #search>
          <el-form :model="form" :inline="true" ref="formRef">
            <el-form-item label="关键词：" prop="keyword">
              <el-input
                v-model="form.keyword"
                placeholder="请输入关键词或#ID进行搜索"
                clearable
                style="width: 220px"
              />
            </el-form-item>
            <el-form-item
              label="报名活动："
              prop="keyword"
              v-if="props.isActivity"
            >
              <el-input
                v-model="form.activityKeyword"
                placeholder="请输入活动关键词搜索"
              />
            </el-form-item>

            <el-form-item label="栏目" prop="nodeId" v-if="!props.isActivity">
              <el-tree-select
                v-model="form.nodeId"
                :data="columnTreeData"
                highlight-current
                style="width: 300px"
                check-strictly
                default-expand-all
              />
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="loading = true">
                查询
              </el-button>
              <el-button type="primary" @click="onSubmit"> 确定选择 </el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #title="scope">
          {{ scope.row.shortTitle || scope.row.title }}
        </template>
        <template #siteApp="scope">
          {{ scope.row.siteApp === 1 ? "APP" : null }}
          {{ scope.row.siteWww === 1 ? "WWW" : null }}
        </template>
        <template #operate="scope">
          <ArticlePreview :row="scope.row" />
        </template>
      </PaiTable>
    </el-dialog>
    <QRcodeCom v-model:visible="QRcodeComVisible" />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, reactive } from "vue";
import { searchQueryEdit, nodeList, activityContList } from "@/api/power";
import { nodecontPreviewSign } from "@/api/lanmu";
import { loadEnv } from "@build/index";
import { perviewH5, isMobile } from "@/utils/index";
import { ElMessage } from "element-plus";
import QRcodeCom from "@/components/QRcodeCom/index.vue";
import { Search } from "@element-plus/icons-vue";

const QRcodeComVisible = ref("");

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: "选择文章"
  },
  openNode: { type: Boolean },
  isActivity: {
    type: Boolean,
    default: false
  },
  activityId: {
    type: Number,
    default: 0
  },
  type: {
    type: Number,
    default: 0
  }
});
const emits = defineEmits(["update:visible", "onSelectData"]);
const dialogVisible = ref(false);
const loading = ref(false);
const selectTable = ref([]);
const formRef = ref();

const fn = ref(searchQueryEdit);
const handleClose = () => {
  emits("update:visible");
};

const columnTreeData = ref([]);
const activityData = ref([]);

const form = reactive({
  keyword: "",
  nodeId: "",
  activityId: 0,
  type: 3,
  word: "",
  activityKeyword: ""
});
const columns = ref([
  { prop: "contId", label: "ID", width: "100" },
  { prop: "title", label: "标题", slot: "title" },
  { prop: "nodeName", label: "所属栏目" },
  { prop: "createUserName", label: "作者" },
  { prop: "updateTime", label: "发布时间", width: 180 },
  { prop: "firstPublishTime", label: "首次发布时间", width: 180 },
  { prop: "operate", label: "操作", width: 100, slot: "operate" }
]);

const onSubmit = () => {
  if (selectTable.value.length) {
    emits("onSelectData", selectTable.value);
    emits("update:visible");
  } else {
    ElMessage.warning("请选择文章！");
  }
};

const handleHeavy = (arr, callback, childrenKey = "children", level) => {
  Array.isArray(arr) &&
    arr.forEach(item => {
      callback && callback(item, level);
      if (Array.isArray(item[childrenKey])) {
        handleHeavy(item[childrenKey], callback, childrenKey, level + 1);
      }
    });
};
const { VITE_APP_PAPER_WAP } = loadEnv();
//  预览跳转，需要在后面跟上参数?to=preview，否则无效
const onPreview = data => {
  console.log(data);

  if (VITE_APP_PAPER_WAP) {
    nodecontPreviewSign({
      contentEditId: data.contId
    }).then(res => {
      if (res.code === 200) {
        if (props.type === 3) {
          window.open(
            `${VITE_APP_PAPER_WAP}/activityWorks/${data.contId}?sign=${res.data.sign}&timestamp=${res.data.timestamp}`
          );
        } else {
          QRcodeComVisible.value = perviewH5(
            data.contId,
            data.contType,
            data.link,
            res.data
          );
        }
      }
    });
  }
};
const getMenuTree = () => {
  nodeList({ type: 0 }).then(res => {
    if (res.code === 200) {
      handleHeavy(
        res.data,
        item => {
          item.label = item.name;
          item.value = item.id;
        },
        "children",
        null
      );
      columnTreeData.value = res.data;
    }
  });
};
const getActiveTree = () => {
  nodeList({}).then(res => {
    if (res.code === 200) {
      handleHeavy(
        res.data,
        item => {
          item.label = item.name;
          item.value = item.id;
        },
        "children",
        null
      );
      columnTreeData.value = res.data;
    }
  });
};

watch(
  () => props.visible,
  newVal => {
    dialogVisible.value = newVal;
    console.log(props.activityId, "eeeeee");

    if (newVal) {
      if (props.isActivity) {
        fn.value = activityContList;
        form.activityId = props.activityId;
        form.type = props.type;
        // getActiveTree();
      } else {
        getMenuTree();
      }
      loading.value = true;
    } else {
      formRef.value?.resetFields();
      selectTable.value = [];
    }
  }
);
</script>

<style scoped lang="scss">
.tag_div {
  display: flex;
  gap: 4px;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 6px;

  span:nth-of-type(1) {
    color: #999;
    font-weight: 400;
  }

  span:nth-of-type(2) {
    color: #333;
    font-weight: 500;
  }
}

.preview_div {
  position: absolute;
  top: 0;
  right: 0;
  color: #024ac2;
}

.select_words {
  :deep(.el-drawer__body) {
    padding: 16px 12px;

    .title_div_word {
      font-weight: 600;
      font-size: 16px;
      color: #333333;
      line-height: 24px;
      text-align: center;
      position: relative;
      overflow: hidden;

      .close_div {
        position: absolute;
        right: 0;
        top: 0;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 24px;
      }
    }

    .sum_div {
      width: 263px;
      height: 40px;
      background: #024ac2;
      border-radius: 2px;
      position: absolute;
      left: 50%;
      bottom: 34px;
      transform: translateX(-50%);
      z-index: 10;
      font-size: 14px;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }
  }

  :deep(.form_mobile) {
    padding: 8px 0;
    gap: 8px;
    display: flex;
    margin-top: 12px;

    .el-form-item {
      margin-bottom: 0;
      margin-right: 0;
      flex: 1;
    }
  }
}
</style>
